import React, {Component} from 'react';
import {NavLink, Link, withRouter} from 'react-router-dom';
import {avater, logo} from '../../../utils/Constants';
import {
    home,
    contact,
    price,
    featuredCourse,
    myCourses,
    progressReport,
    userProfile,
} from '../../../utils/routes';
import {Dropdown, Navbar, Nav} from 'react-bootstrap';
import Bdt1 from "../../../assets/static/svg/bdt1.svg";
import Logout from "../../../assets/static/svg/logout.svg";
import ProgressReport from "../../../assets/static/svg/progressReport.svg";
import AllTakenCourse from "../../../assets/static/svg/allTakenCourse.svg";
import {ReactSVG} from 'react-svg';
import {loadState, removeStoreById, saveState} from "../../../LocalStore/localStorage";
import {connect} from "react-redux";
import {getStudentInfo, studentLogin} from "../../../redux/actions/auth";
import {withTranslation} from 'react-i18next';
import LoginModal from "../../modal/loginModal";
import LoginPage from "../../modal/loginPage";
import {setLocalization} from "../../../redux/actions/localization";
import defaultImgSetup from "../../../redux/helper/defaultImgSetup";
import {getPhoneNumber, getSubInfo} from "../../../redux/actions/subInfo";
import {sendAccessInfo} from "../../../redux/actions/accessLog";

export class Header extends Component {
    constructor(props) {
        super(props);
        this.state = {
            position: 0,
            studentId: loadState('uuid'),
            msisdn: loadState('msisdn'),
            lng: 'bn'
        }
    }

    async componentDidMount() {
        const {userInfo} = this.props;
        const {studentId, msisdn} = this.state;
        await this.props.dispatch(setLocalization('en'));
        // await this.props.dispatch(getSubInfo()); wait for ip whitelist
        await this.props.dispatch(getPhoneNumber());
        // console.log(12, this.props.getMsisdn.user_info)

        // if auto robi internet get
        if (this.props.getMsisdn && this.props.getMsisdn.user_info) {
            await saveState('msisdn', this.props.getMsisdn.user_info.msisdn);
            await this.props.dispatch(studentLogin(this.props.getMsisdn.user_info.msisdn));
            if (this.props.userInfo) {
                await saveState('uuid', this.props.userInfo.student_id);
                await this.props.dispatch(getStudentInfo(this.props.userInfo.student_id));
                await this.props.dispatch(getSubInfo(this.props.getMsisdn.user_info.msisdn));

                await this.setState({
                    msisdn: this.props.getMsisdn.user_info.msisdn,
                    studentId: this.props.userInfo.student_id
                })
            }
        }

        if (studentId && userInfo.length < 1) {
            await this.props.dispatch(getStudentInfo(studentId));
            await this.props.dispatch(getSubInfo(msisdn));
            // console.log(12, this.props.userInfo)

        }
        await window.addEventListener("scroll", this.listenToScroll);
    }

    // async componentDidUpdate(prevProps, prevState, snapshot) {

    //     await this.props.dispatch(sendAccessInfo(prevState.msisdn));


    // }

    componentDidUpdate(prevProps) {
        // Re-render Header when location changes
        if (this.props.location.pathname !== prevProps.location.pathname) {
            this.forceUpdate(); // Force a re-render when route changes
        }
    }

    componentWillUnmount() {
        window.removeEventListener("scroll", this.listenToScroll);
    }


    listenToScroll = () => {
        const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop;

        const height =
            document.documentElement.scrollHeight -
            document.documentElement.clientHeight;
        // const scrolled = winScroll / height;
        const scrolled = window.scrollY;

        this.setState({
            position: scrolled
        });
    };

    logout = async () => {
        await removeStoreById('uuid');
        await removeStoreById('msisdn');
        await window.location.reload()
    };

    changeLanguage = async lng => {
        await this.setState({lng: lng === 'en' ? 'bn' : 'en'});
        await this.props.dispatch(setLocalization(lng === 'en' ? 'bn' : 'en')); // optional or not used
        await this.props.i18n.changeLanguage(lng);
    };

    render() {
        const {position, studentId, lng} = this.state;
        const {userInfo, getSubInfo, t, getMsisdn, location} = this.props;
        const {data } = getMsisdn;
        return (
            <header className={position > 150 ? "header headerSticky" : "header"} id="header">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <Navbar expand="lg">
                                <Link to={home} className="logo"><img src={logo} alt="img"/></Link>
                                <Navbar.Toggle aria-controls="mainMenu">
                                    <span className="bar"></span>
                                    <span className="bar"></span>
                                    <span className="bar"></span>
                                </Navbar.Toggle>
                                <Navbar.Collapse id="mainMenu" className="mainMenu">
                                    <Nav className="ml-auto">
                                        <li className="nav-item">
                                            <form action="#" className="searchForm">
                                                <input type="text" className="searchInput"/>
                                                <button className="btnSearch"><i className="fa fa-search"></i></button>
                                            </form>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to={home} className="nav-link">{t('home')}</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to={featuredCourse}
                                                     className="nav-link">{t('featuredCourses')}</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to={price} className="nav-link">{t('pricing')}</NavLink>
                                        </li>
                                        {studentId &&
                                        <li className="nav-item">
                                            <NavLink to={myCourses} className="nav-link">{t('myCourse')}</NavLink>
                                        </li>
                                        }
                                        <li className="nav-item">
                                            <NavLink to={contact} className="nav-link">{t('contact')}</NavLink>
                                        </li>
                                        <li className="nav-item">
                                            <NavLink to={'#'} className="nav-link joinBtn"
                                                     onClick={() => this.changeLanguage(lng)}>{t('ln')}</NavLink>
                                        </li>

                                        {!studentId && data && data.errors &&
                                        <li className="nav-item">
                                            <LoginModal/>
                                        </li>
                                        }
                                        {!studentId && data && data.errors && location.pathname !== '/home' &&
                                        <li className="nav-item">
                                            <LoginPage/>
                                        </li>
                                        }
                                        {studentId && userInfo &&
                                        <li className="nav-item">
                                            <Dropdown className="afterLogin">
                                                <Dropdown.Toggle variant="success" id="dropdown-basic">
                                                    <div className="userIcon">
                                                        <div className="userImd">
                                                            <img src={defaultImgSetup(userInfo.profile_pic)}
                                                                 alt={"avater"}/>
                                                        </div>
                                                    </div>
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                    <Link to={userProfile} className="userProfile">
                                                        <div className="useImg">
                                                            <img src={defaultImgSetup(userInfo.profile_pic)}
                                                                 alt={"avater"}/>
                                                        </div>
                                                        <div className="userInfo">
                                                            <h6 className="userName">{userInfo.first_name || ''} {userInfo.last_name || 'Update Profile'}</h6>
                                                            {/*<p className="userPhone">{avater}</p>*/}
                                                        </div>
                                                    </Link>
                                                    <ul className="dropdownMenuList">
                                                        <li>
                                                            <Link to={price}>
                                                                <span className="text">Pricing plan</span>
                                                                <span className="float-right">
                                                                        <ReactSVG src={Bdt1}/>
                                                                    </span>
                                                            </Link>
                                                        </li>
                                                        {/* <li>
                                                            <Link to={notification}>
                                                                <span className="text">Notification</span>
                                                                <span className="float-right">
                                                                    <span className="note">3</span>
                                                                </span>
                                                            </Link>
                                                        </li> */}
                                                        <li>
                                                            <Link to={myCourses}>
                                                                <span className="text">All Taken Course</span>
                                                                <span className="float-right">
                                                                        <ReactSVG src={AllTakenCourse}/>
                                                                    </span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to={progressReport}>
                                                                <span className="text">Progress Report</span>
                                                                <span className="float-right">
                                                                        <ReactSVG src={ProgressReport}/>
                                                                    </span>
                                                            </Link>
                                                        </li>
                                                        <li>
                                                            <Link to={"#"} onClick={() => this.logout()}>
                                                                <span className="text">Log out</span>
                                                                <span className="float-right">
                                                                        <ReactSVG src={Logout}/>
                                                                    </span>
                                                            </Link>
                                                        </li>
                                                    </ul>
                                                </Dropdown.Menu>
                                            </Dropdown>
                                        </li>
                                        }
                                    </Nav>
                                </Navbar.Collapse>
                            </Navbar>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
}

const mapStateToProps = (state) => ({
    subInfo: state.subInfo,
    userInfo: state.userInfo,
    localization: state.localizationReducer,
    getSubInfo: state.getSubInfo,
    getMsisdn: state.getMsisdn

});

export default withTranslation()(connect(mapStateToProps)(withRouter(Header)));
