import React, {Component} from 'react';
import {ReactSVG} from "react-svg";
import Tabs from 'react-bootstrap/Tabs'
import Bdt from "../../assets/static/svg/bdt.svg";
import {Tab} from 'react-bootstrap';
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import LangChanger from "../../redux/helper/lngChanger";
import {getSubInfo} from "../../redux/actions/subInfo";
import {loadState} from "../../LocalStore/localStorage";

export class PriceTable extends Component {
    constructor(props) {
        super(props);

        this.state = {
            close: false,
            msisdn: loadState('msisdn'),
            packList: [],
            pack: null,
            user_info: null,
            ifSub: null
        };

        this.onSelectHandle = this.onSelectHandle.bind(this);
    }

    async componentDidMount() {
        await this.props.dispatch(getSubInfo(this.state.msisdn));
        const {pack_list, user_info} = this.props.getSubInfo.error ? [] : this.props.getSubInfo;
        await this.setState({packList: pack_list, pack: pack_list ? pack_list[1] : null, user_info});
        await this.isSubInfoFilter();
    }

    async componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.state.packList && this.state.packList.length < 1) {
            const {pack_list, user_info} = this.props.getSubInfo.error ? [] : this.props.getSubInfo;
            await this.setState({packList: pack_list, pack: pack_list[1], user_info});
            await this.isSubInfoFilter();


        }
    }

    onSelectHandle = async (key) => {
        const {packList} = this.state;
        const pack = packList.filter(item => item.pack_name_en === key);
        await this.setState({pack: pack[0]});
    };

    isSubInfoFilter = async () => {
        const {packList} = this.state;
        const pack = packList.filter(item => item.is_subscribe === true);
        await this.setState({ifSub: pack[0]});
    };

    render() {
        const {t} = this.props;
        const {packList, pack, user_info, ifSub} = this.state;
        // console.log(pack, user_info);

        return (
            <div className="priceTableWrap" id="landingPrice">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="priceTableTop">
                                <h2 className="secTitle2 text-center">{t('PricingTable')}</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row align-items-center justify-content-center">
                        {!ifSub && packList && packList.length > 0 && packList.slice(0, 4).map((item, i) =>
                            <div className="col-lg-4 col-md-6 col-sm-12 col-12" key={i}>
                                <div className="pricingSingleItem">
                                    <div className="pricingInner">
                                        <h5 className="text-center priceCategary">{LangChanger(t('ln'), item.pack_name_en, item.pack_name_bn)}</h5>
                                        <h2 className="secTitle text-center">{item.pack_type}</h2>
                                        <h1 className="text-center price">
                                            <span className="priceInner">
                                                <span
                                                    className="priceCount">{LangChanger(t('ln'), item.price_en, item.price_bn)}</span>
                                                <span className="bdtC">
                                                    <ReactSVG src={Bdt}/>
                                                </span>
                                            </span>
                                        </h1>
                                        <ul className="priceForList text-center">
                                            <li>{t('DigitalClassroomGroupLearning')}</li>
                                            <li>{t('AccessToAllDigitalVideoContents')}</li>
                                            <li>{t('AccessToHajjContents')}</li>
                                            <li>{t('IVRContents')}</li>
                                            <li>{t('ModuleBasedQuiz')}</li>
                                            <li>{t('ModuleCompletionExam')}</li>
                                            <li>{t('DigitalCertificates')}</li>
                                        </ul>
                                        {user_info && user_info.sub_status === 0 &&
                                        <div className="grtStartedBtn text-center">
                                            <a href={item.sub_unsub_url} className="btnStart">Subscribe</a>
                                        </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        )}

                        {/*{!ifSub &&*/}
                        {/*<div className="col-lg-4 col-md-6 col-sm-12 col-12">*/}
                        {/*    <div className="pricingSingleItem active">*/}
                        {/*        <div className="pricingInner">*/}
                        {/*            <h5 className="text-center priceCategary">{t('SUBSCRIPTION')}</h5>*/}
                        {/*            <div className="pricingTabContent">*/}
                        {/*                <Tabs className="pricTAb" defaultActiveKey="weekly"*/}
                        {/*                      onSelect={(e) => this.onSelectHandle(e)}>*/}
                        {/*                    {packList && packList.length > 0 && packList.slice(0, 3).map((item, i) =>*/}
                        {/*                        <Tab key={i} eventKey={item.pack_name_en}*/}
                        {/*                             title={LangChanger(t('ln'), item.pack_name_en, item.pack_name_bn)}>*/}
                        {/*                            <h1 className="text-center price">*/}
                        {/*                                    <span className="priceInner">*/}
                        {/*                                        <span*/}
                        {/*                                            className="priceCount">{LangChanger(t('ln'), item.price_en, item.price_bn)}</span>*/}
                        {/*                                        <span className="bdtC">*/}
                        {/*                                            <ReactSVG src={Bdt}/>*/}
                        {/*                                        </span>*/}
                        {/*                                    </span>*/}
                        {/*                            </h1>*/}
                        {/*                            <ul className="priceForList text-center">*/}
                        {/*                                <li>{t('DigitalClassroomGroupLearning')}</li>*/}
                        {/*                                <li>{t('AccessToAllDigitalVideoContents')}</li>*/}
                        {/*                                <li>{t('AccessToHajjContents')}</li>*/}
                        {/*                                <li>{t('IVRContents')}</li>*/}
                        {/*                                <li>{t('ModuleBasedQuiz')}</li>*/}
                        {/*                                <li>{t('ModuleCompletionExam')}</li>*/}
                        {/*                                <li>{t('DigitalCertificates')}</li>*/}
                        {/*                            </ul>*/}
                        {/*                        </Tab>*/}
                        {/*                    )}*/}
                        {/*                </Tabs>*/}
                        {/*            </div>*/}
                        {/*            {pack && Object.keys(pack).length > 0 && user_info && user_info.sub_status === 0 &&*/}
                        {/*            <div className="grtStartedBtn text-center">*/}
                        {/*                <a href={pack.sub_unsub_url} className="btnStart">Subscribe</a>*/}
                        {/*            </div>*/}
                        {/*            }*/}

                        {/*            {pack && Object.keys(pack).length > 0 && user_info && user_info.sub_status !== 0 &&*/}
                        {/*            <div className="grtStartedBtn text-center">*/}
                        {/*                <a href={pack.is_subscribe ? pack.sub_unsub_url : "#"}*/}
                        {/*                   className="btnStart">Unsubscribe</a>*/}
                        {/*            </div>*/}
                        {/*            }*/}
                        {/*        </div>*/}
                        {/*    </div>*/}
                        {/*</div>*/}
                        {/*}*/}

                        {/*If Subscribe */}
                        {ifSub && Object.keys(ifSub).length > 0 &&
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="pricingSingleItem active pricingSingleItemSub">
                                <div className="pricingInner">
                                    <h5 className="text-center priceCategary">Subscribed </h5>
                                    <h className="secTitle text-center">{LangChanger(t('ln'), ifSub.pack_name_en, ifSub.pack_name_bn)}</h>
                                    <h2 className="secTitle text-center">{ifSub.pack_type}</h2>
                                    <h1 className="text-center price">
                                            <span className="priceInner">
                                                <span
                                                    className="priceCount">{LangChanger(t('ln'), ifSub.price_en, ifSub.price_bn)}</span>
                                                <span className="bdtC">
                                                    <ReactSVG src={Bdt}/>
                                                </span>
                                            </span>
                                    </h1>
                                    <ul className="priceForList text-center">
                                        <li>{t('DigitalClassroomGroupLearning')}</li>
                                        <li>{t('AccessToAllDigitalVideoContents')}</li>
                                        <li>{t('DownloadToModuleBasedDigitalBooks')}</li>
                                        <li>{t('AccessToHajjContents')}</li>
                                        <li>{t('IVRContents')}</li>
                                        <li>{t('ModuleBasedQuiz')}</li>
                                        <li>{t('ModuleCompletionExam')}</li>
                                        <li>{t('DigitalCertificates')}</li>
                                    </ul>
                                    <div className="grtStartedBtn text-center">
                                        <a href={ifSub.sub_unsub_url}
                                           className="btnStart">{t('Unsubscribe')}</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        }

                        {/*<div className="col-lg-4 col-md-6 col-sm-12 col-12">*/}
                        {/*    {packList && packList.length > 0 && packList.slice(3, 4).map((item, i) =>*/}
                        {/*        <div key={i} className="pricingSingleItem">*/}
                        {/*            <div className="pricingInner">*/}
                        {/*                <h5 className="text-center priceCategary">{t('ON_DEMAND')}</h5>*/}
                        {/*                <h1 className="text-center price">*/}
                        {/*                    <span className="priceInner">*/}
                        {/*                        <span*/}
                        {/*                            className="priceCount">{LangChanger(t('ln'), item.price_en, item.price_bn)}</span>*/}
                        {/*                        <span className="bdtC">*/}
                        {/*                            <ReactSVG src={Bdt}/>*/}
                        {/*                        </span>*/}
                        {/*                    </span>*/}
                        {/*                </h1>*/}
                        {/*                <ul className="priceForList text-center">*/}
                        {/*                    <li>{t('DigitalClassroomGroupLearning')}</li>*/}
                        {/*                    <li>{t('AccessToAllDigitalVideoContents')}</li>*/}
                        {/*                    <li>{t('DownloadToModuleBasedDigitalBooks')}</li>*/}
                        {/*                    <li>{t('AccessToHajjContents')}</li>*/}
                        {/*                    <li>{t('IVRContents')}</li>*/}
                        {/*                    <li>{t('ModuleBasedQuiz')}</li>*/}
                        {/*                    <li>{t('ModuleCompletionExam')}</li>*/}
                        {/*                    <li>{t('DigitalCertificates')}</li>*/}
                        {/*                </ul>*/}
                        {/*                <div className="grtStartedBtn text-center">*/}
                        {/*                    <a href={item.sub_unsub_url}*/}
                        {/*                       className="btnStart">{t('alhamdulillah')} ! {t('GetStarted')}</a>*/}
                        {/*                </div>*/}
                        {/*            </div>*/}
                        {/*        </div>*/}
                        {/*    )}*/}
                        {/*</div>*/}
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    getSubInfo: state.getSubInfo
});

export default withTranslation()(connect(mapStateToProps)(PriceTable));
