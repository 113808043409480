import React, {Component} from 'react';
import {Modal, Button, Form} from "react-bootstrap";
import {Link} from 'react-router-dom';
import {getOtp, studentLogin} from "../../redux/actions/auth";
import {saveState} from "../../LocalStore/localStorage";
import {connect} from "react-redux";
import decrypt from "../../utils/decrypt";

class LoginPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            phoneNumber: null,
            otp: null,
            inputOtp: null,
            loader: false,
            loaderOtp: false,
            modalShow: true,
            validatePhoneNumber: false,
            invalidOtp: false
        }
    }

    submit = async (e) => {
        e.preventDefault();
        const {phoneNumber} = this.state;
        await this.setState({loader: true});
        await this.props.dispatch(getOtp(phoneNumber));
        if (this.props.otp) {
            await this.setState({otp: this.props.otp.otp, loader: false});
        }
    };

    submitOtp = async (e) => {
        e.preventDefault();
        const {inputOtp, otp, phoneNumber} = this.state;
        await this.setState({loaderOtp: true});
        const decOpt = decrypt(otp);
        if (decOpt === inputOtp) {
            await this.props.dispatch(studentLogin(phoneNumber));
            await saveState('msisdn', phoneNumber);
            if (this.props.userInfo) {
                await saveState('uuid', this.props.userInfo.student_id);
                await this.setState({modalShow: false});
                window.location.href = "/"
            }
            await this.setState({loaderOtp: false});
        } else {
            await this.setState({loaderOtp: false, invalidOtp: true});

        }
    };

    handleChange = (e) => {
        this.setState({phoneNumber: e.target.value.trim()})
    };

    handleChangeOtp = (e) => {
        this.setState({inputOtp: e.target.value.trim()})
    };

    handleClose = () =>{
        this.setState({modalShow: false});
        window.location.href = '/home';
    };


    render() {
        const {phoneNumber, loader, otp, loaderOtp, modalShow, validatePhoneNumber, invalidOtp} = this.state;

        return (
            <>
                {/* <div className="text-center">
                    <Button className="nav-link joinBtn" onClick={() => this.setState({modalShow: true})}>
                        Login
                    </Button>
                </div> */}

                <Modal
                    show={modalShow}
                    onHide={this.handleClose}
                    className="loginModal"
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                >
                    <Modal.Header closeButton>
                        <Modal.Title id="contained-modal-title-vcenter">
                            <h4 className="modalTitle text-center">Login to your IQRA Account</h4>
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div className="loginModalBody">
                            {otp === null && !loader &&
                            <form onSubmit={this.submit} className="loginForm">
                                <div className="form-group get-opt-form">
                                    <h6 className="log-title">
                                        Enter your mobile number
                                    </h6>
                                    <div className="otp-content">
                                        <div className="input-number">
                                            <input
                                                className="form-control"
                                                id="mobile"
                                                autoFocus={true}
                                                autoComplete="off"
                                                onChange={this.handleChange} placeholder="Enter Mobile Number"
                                                required
                                            />
                                            <div className="icon">
                                                <i className="fa fa-mobile"></i>
                                            </div>
                                        </div>
                                        {((phoneNumber && phoneNumber.length < 11) || (phoneNumber && phoneNumber.length > 12)) &&
                                        <small className="text-danger">Invalid Phone Number</small>
                                        }
                                        <div className="btnGroup">
                                            <button
                                                type="submit"
                                                className="btn btn-primary form-control"
                                                disabled={loader || (phoneNumber && phoneNumber.length < 11)}
                                            >
                                                Sign In {loader &&
                                            <i className="spinner-border"></i>}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            }
                            {otp !== null && !loader &&

                            <form onSubmit={this.submitOtp} className="loginForm">
                                <div className="form-group get-opt-form otp-submit-form">
                                    <h6 className="log-title">Enter OTP</h6>
                                    <input type="text" className="form-control" onChange={this.handleChangeOtp}
                                           placeholder="Enter OTP"/>
                                    {invalidOtp &&
                                    <p><small className="text-danger">Invalid OTP</small></p>
                                    }
                                    <br/>
                                    {/*<div className="opt-input-group">*/}
                                    {/*    <input*/}
                                    {/*        style={{pointerEvents: "visible"}}*/}
                                    {/*        className="form-control"*/}
                                    {/*        autoComplete="off"*/}
                                    {/*        required*/}
                                    {/*        autoFocus="true"*/}
                                    {/*        type="text"*/}
                                    {/*        onChange={this.handleChangeOtp} placeholder="X"*/}
                                    {/*    />*/}
                                    {/*    <input*/}
                                    {/*        style={{pointerEvents: "visible"}}*/}
                                    {/*        className="form-control"*/}
                                    {/*        autoComplete="off"*/}
                                    {/*        required*/}
                                    {/*        disabled={true}*/}
                                    {/*    />*/}
                                    {/*    <input*/}
                                    {/*        style={{pointerEvents: "visible"}}*/}
                                    {/*        className="form-control"*/}
                                    {/*        autoComplete="off"*/}
                                    {/*        required*/}
                                    {/*        disabled={true}*/}
                                    {/*    />*/}
                                    {/*    <input*/}
                                    {/*        style={{pointerEvents: "visible"}}*/}
                                    {/*        className="form-control"*/}
                                    {/*        autoComplete="off"*/}
                                    {/*        required*/}
                                    {/*        disabled={true}*/}
                                    {/*    />*/}
                                    {/*    <input*/}
                                    {/*        style={{pointerEvents: "visible"}}*/}
                                    {/*        className="form-control"*/}
                                    {/*        autoComplete="off"*/}
                                    {/*        required*/}
                                    {/*        disabled={true}*/}
                                    {/*    />*/}
                                    {/*    <input*/}
                                    {/*        style={{pointerEvents: "visible"}}*/}
                                    {/*        className="form-control"*/}
                                    {/*        autoComplete="off"*/}
                                    {/*        required*/}
                                    {/*        disabled={true}*/}
                                    {/*    />*/}
                                    {/*</div>*/}

                                    <div className="btnGroup">
                                        <button
                                            type="submit"
                                            className="btn btn-primary form-control" disabled={loaderOtp}
                                        >
                                            Submit {loaderOtp &&
                                        <i className="spinner-border"></i>}
                                        </button>
                                    </div>
                                </div>

                                <div className="resendOpt">
                                    <p className="text-center"><Link to={"#"}>Resend OTP </Link></p>
                                </div>

                                {/*<div className="newToIqra">*/}
                                {/*    <p className="text-center">New to IQRA? <Link to={"/"}>Sign UP</Link></p>*/}
                                {/*</div>*/}

                                {/*<div className="accecptCondition">*/}
                                {/*<span className="customCheckbok">*/}
                                {/*    <input type="checkbox" id="accecpt"/>*/}
                                {/*    <label htmlFor="accecpt"*/}
                                {/*           className="conditionText">I accept the <Link to={"#"}>terms & Policy</Link> of IQRA.</label>*/}
                                {/*</span>*/}
                                {/*</div>*/}
                            </form>
                            }
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

const mapStateToProps = (state) => ({
    otp: state.otp,
    subInfo: state.subInfo,
    userInfo: state.userInfo,
    getMsisdn: state.getMsisdn

});
export default connect(mapStateToProps)(LoginPage);